/* Global Styling here */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  height: 100%;
  font-family: "Inter", sans-serif;
}

a {
  @apply text-orange-500 hover:text-red-550;
}
.a-border {
  @apply border border-solid border-orange-500 px-6 py-1 hover:border-red-550;
}

input[type="text"] {
  @apply border border-gray-300 bg-white focus:border-orange-400 focus:ring-2 focus:ring-orange-400/50;
}

/* react-toastify overrides */

:root {
  --metatrust-orange: #f09500;
  --metatrust-red: #e53820;
  --toastify-color-light: #f9f9f9;
  --toastify-color-dark: #333333;
  --toastify-color-info: #3b82f6;
  --toastify-color-success: #84cc16;
  --toastify-color-warning: #f09400;
  --toastify-color-error: #e53820;
  --toastify-font-family: "Inter", sans-serif;
  --toastify-color-progress-light: linear-gradient(
    to right,
    var(--metatrust-orange),
    var(--metatrust-red)
  );
  --toastify-color-progress-dark: var(--toastify-color-progress-light);
  --toastify-toast-width: 500px;
}

.react-datepicker .react-datepicker__day--keyboard-selected {
  background-color: #f09400 !important;
  /* border-radius: 100%; */
}

.react-datepicker .react-datepicker__day--selected {
  background-color: #f09400 !important;
  /* border-radius: 100%; */
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #f09400 !important;
}

@keyframes bounce-right {
  0%,
  100% {
    transform: translateX(25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
@keyframes bounce-left {
  0%,
  100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
@keyframes bounce-up {
  0%,
  100% {
    transform: translateY(25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.animate-bounce-right {
  animation: bounce-right 1s infinite;
}
.animate-bounce-left {
  animation: bounce-left 1s infinite;
}
.animate-bounce-up {
  animation: bounce-up 1s infinite;
}

@layer utilities {
  .sentry-btn-base {
    @apply flex items-center justify-center gap-2 rounded-lg border border-orange-400 bg-gradient-to-br py-2 px-5 text-white outline-orange-300;
  }
}
.sentry-error-embed form .form-submit .btn {
  @apply sentry-btn-base from-orange-400 to-red-500;
}
.sentry-error-embed form .form-submit .btn:hover {
  @apply sentry-btn-base from-orange-500 to-red-600;
}
.sentry-error-embed form .form-submit .btn:active {
  @apply sentry-btn-base from-orange-600 to-red-600 shadow-none;
}
